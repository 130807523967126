.l-error {
  position: fixed;
  background: #fce0e3;
  width: 100%;
  height: 100vh;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.error_title {
  margin: 0 auto 15px;
  font-family: Noto Sans JP;
  font-size: 80px;
  font-weight: 900;
  line-height: 1;
  color: #e60012;
  text-align: center;
}

.error_sub_title {
  margin: 0 auto 35px;
  font-family: Noto Sans JP;
  font-size: 24px;
  font-weight: 400;
  line-height: 1;
  color: #e60012;
  text-align: center;
}

.error_japanese {
  width: 250px;
  height: 60px;
  margin: 0 auto 15px;
  font-family: Noto Sans JP;
  font-size: 20px;
  font-weight: 400;
  line-height: 60px;
  color: #e60012;
  background: #fff;
  border: 1px solid #e60012;
  box-sizing: border-box;
  border-radius: 10px;
  text-align: center;
}

.error_text {
  font-family: Noto Sans JP;
  font-size: 12px;
  color: #e60012;
  text-align: center;
}

.will {
  margin: 0 auto;
}

.will_img {
  width: 100%;
}