.error-modal {
  position: fixed;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  padding: 15px;
  background-color: #212121;
  z-index: 998;
}

.error_modal-inner {
  max-width: 345px;
  width: 100%;
  padding: 30px 15px;
  background: #fce0e3;
  border-radius: 10px;
  text-align: center;
}

h2 {
  margin: 15px 0 30px;
  font-family: Noto Sans JP;
  font-size: 20px;
  line-height: 1;
  font-weight: bold;
  color: #e60012;
}

p {
  font-family: Noto Sans JP;
  font-size: 12px;
  color: #000;
}