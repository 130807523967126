.local_video_wrapper {
  position: fixed;
  display: flex;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.7);
}

.join-form {
  text-align: center;
}

.connect_caption {
  margin: 0 0 30px;
  font-size: 16px;
  color: #fff;
}

.button-join,
.button-join:hover,
.button-join:visited,
.button-join:active,
.button-join:disabled {
  padding: 10px 15px;
  font-family: Noto Sans JP;
  font-weight: bold;
  font-size: 20px;
  line-height: 1;
  border: none;
  border-radius: 30px;
  outline: none;
  color: #fff;
  background: #28a1e8;
  cursor: pointer;
}

.button-join:disabled {
  background: #c6c6c6;
  cursor: default;
}

@media all and (max-width: 500px) {
  .connect_caption {
    width: 242px;
  }
}