.menubar {
  display: flex;
  position: relative;
  width: 100%;
  height: 48px !important;
  background-color: #0f0f0f !important;
  z-index: 2;
}

.userName {
  position: absolute;
  display: inline-flex;
  top: 36px;
  left: 10px;
  padding: 0.1em 0.3em;
  margin-block-start: 1.33em;
  margin-block-end: 1.33em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-family: Noto Sans JP;
  font-size: 1.2em;
  font-weight: bold;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 3px;
  z-index: 10;
}

.menubar button {
  padding: 0;
}

.menubar .button-call_end,
.menubar .button-call_end:hover,
.menubar .button-call_end:visited,
.menubar .button-call_end:active,
.menubar .button-call_end:disabled {
  width: 30px;
  height: 30px;
  min-height: 30px;
  margin: 0 10px 0 0;
  padding: 0;
}

.menubar .button-call_end:disabled {
  background: #c6c6c6;
  cursor: default;
}
