.modal {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  padding: 15px;
  background-color: #212121;
  touch-action: manipulation;
}

.modal-inner {
  max-width: 345px;
  width: 100%;
  padding: 30px 15px;
  background: #fff;
  border-radius: 10px;
}

.input-dl {
  margin: 0;
}

.input-dl > dt {
  display: block;
  font-size: 9px;
  line-height: 1;
  color: rgba(0, 0, 0, 0.54);
  margin-bottom: 5px;
}

.input-dl > dd {
  position: relative;
  margin: 0 0 30px 0;
}

.error-text {
  position: absolute;
  bottom: -30px;
  left: 0;
  font-family: Noto Sans JP;
  font-size: 10px;
  line-height: 14px;
  color: #e60012;
}

.button {
  width: 100%;
  max-width: 315px;
  height: 40px;
  font-family: Noto Sans JP;
  font-weight: bold;
  font-size: 14px;
  line-height: 38px;
  color: #fff;
  background: #28a1e8;
  border: none;
  border-radius: 30px;
  cursor: pointer;
}

.button:disabled {
  width: 100%;
  max-width: 315px;
  height: 40px;
  font-family: Noto Sans JP;
  font-weight: bold;
  font-size: 14px;
  line-height: 38px;
  color: #fff;
  background: #c6c6c6;
  border: none;
  border-radius: 30px;
  cursor: default;
}